// importing modules
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router.js'
import Vue2TouchEvents from 'vue2-touch-events'
import axios from 'axios';
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import WebCam from 'vue-web-cam';
import './registerServiceWorker'

// defining rest api endpoints
Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://bikeshare-backend.cluster.hfg.gd/api/v1/'

// use touch gestures
Vue.use(Vue2TouchEvents)

// use webcam
Vue.use(WebCam);

Vue.config.productionTip = false

// declare global methods
Vue.mixin({
  methods: {
    // shows popup status messages
    toast: function (title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      })
    },
    // gets userprofile from the database
    getUserProfile() {
      return new Promise((resolve, reject) => {
        if (!localStorage.getItem('token')) return reject();
        this.axios.get('user/getUserProfile', {
          headers: {
            token: localStorage.getItem('token'),
          }
        })
          .then((res) => {
            this.$store.state.userProfile = res.data;
            resolve();
          })
          .catch((err) => {
            this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden des Nutzerprofils', 'danger')
            router.push('/start')
            console.error(err);
            reject();
          });
      });
    }
  }
})

// define global varaible store
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    mapLoaded: false,
    loggedIn: false,
    userProfile: {},
    bike: {},
    registration: null,
    mapState: {
      zoom: 0,
      pitch: 0,
      bearing: 0,
      center: [0,0]
    }
  }
});

// check if loggedin
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        name: 'Login',
      });
      this.toast('Es ist ein Fehler aufgetreten', 'Du musst dich zuerst anmelden!', 'danger')
      store.state.loggedIn = false;
    } else {
      next();
      store.state.loggedIn = true;
    }
  } else {
    next();
  }
});

// define vue instance
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
