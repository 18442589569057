<template>
  <div class="wrapper" @click="emitClick">
    <div class="background"></div>
    <b-container class="min-vh-100 d-flex flex-column">
      <b-row class="flex-grow-1">
        <b-col cols="1"/>
        <b-col cols="10" class="justify-content-center align-self-center content">
          <p>
            <!-- shows the amount of saved trees by driving by bike -->
            Hallo {{$store.state.userProfile.preName}}, du hast insgesamt schon <span class="bold">{{$store.state.userProfile.savedTrees === 1 ? 'einen Baum' : `${$store.state.userProfile.savedTrees} Bäume`}}</span> durch dein Fahrrad fahren gerettet.
            Du kannst stolz auf dich sein. <span class="bold">Mach weiter so!</span>
          </p>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TreePopup',
  methods: {
    emitClick() {
      this.$emit('click');
    }
  }
}
</script>

<style scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  cursor: pointer;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  
}
.content {
  background-color: var(--white-color);
  border-radius: .5rem;
  padding: 1rem;
}
.bold {
  font-family: ArchiaSemiBold, Helvetica, Arial, sans-serif;
}
</style>