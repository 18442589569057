<template>
  <div class="marker-wrapper">
    <!-- changes the color of the marker depending on its online state -->
    <div :class="{ marker: true, markerOnline: online, markerOffline: !online }">
      <div class="marker-content">
        <!-- shows the matching bike icon based on its type -->
        <img v-if="type==='bike'" src="@/assets/icons/bike.svg" class="marker-icon"/>
        <img v-if="type==='ebike'" src="@/assets/icons/ebike.svg" class="marker-icon"/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BikeMarker',
  props: {
    type: String,
    seen: String,
  },
  computed: {
    online() {
      // decides wether the bike is online or not by it's last recieved lora message
      return (Date.now() - this.seen) <= 3600000;
    }
  }
}
</script>

<style scoped>

.marker-wrapper {
  z-index: 1;
  padding-bottom: 3rem;
  cursor: pointer;
}

.marker {
  width: 3rem;
  height: 3rem;
  border-radius: 50% 50% 50% 0%;
  transform: rotate(-45deg);
  box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.2);
}

.markerOnline {
  background-color: var(--interaction-color);
}
.markerOffline {
  background-color: var(--secondary-text-icon-color);
}

.marker-content {
  position: absolute;
  transform: rotate(45deg);
  border-radius: 50%;
  background-color: var(--white-color);
  margin: 5%;
  width: 90%;
  height: 90%;
}

.marker-icon {
  margin: 12.25%;
  width: 75%;
  height: 75%;

}
</style>