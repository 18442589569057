<template>
  <div class="backButton" @click="emitEvent('click')">
    <b-icon scale="2" icon="chevron-left"/>
  </div>
</template>

<script>

export default {
  name: 'BackButton',
  props: {
    back: Boolean,
  },
  methods: {
    emitEvent(e) {
      if (this.back) {
        window.history.back();
      }
      this.$emit(e);
    }
  }
}
</script>

<style scoped>
.backButton {
  color: var(--text-icon-color);
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 10;
  cursor: pointer;
}
</style>