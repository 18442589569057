import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// declares the routes for our app screens
const routes = [
  {
    // the path in the browser
    path: '/',
    // declares the page name
    name: 'Home',
    // defines the component file
    component: Home,
    // sets metadata for the route
    meta: {
      // defines if user has to be logged in to view page
      requiresAuth: true,
      // sets the page title
      title: 'HfG Bikeshare'
    }
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('../views/Start.vue'),
    meta: {
      title: 'HfG Bikeshare'
    }
  },
  {
    path: '/driving',
    name: 'Driving',
    component: () => import('../views/Driving.vue'),
    meta: {
      requiresAuth: true,
      title: 'Fahren - HfG Bikeshare'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Anmelden - HfG Bikeshare',
    }
  },
  {
    path: '/tos',
    name: 'Terms of Service',
    component: () => import('../views/ToS.vue'),
    meta: {
      requiresAuth: true,
      title: 'AGB - HfG Bikeshare'
    }
  },
  {
    path: '/tou',
    name: 'Terms of Use',
    component: () => import('../views/ToU.vue'),
    meta: {
      requiresAuth: true,
      title: 'Nutzungsbedingungen - HfG Bikeshare'
    }
  },
  {
    path: '/bugreport',
    name: 'Bug Report',
    component: () => import('../views/BugReport.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bug melden - HfG Bikeshare'
    }
  },
  {
    path: '/appTutorial/1',
    name: 'App Tutorial 1',
    component: () => import('../views/AppTutorial1.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/appTutorial/2',
    name: 'App Tutorial 2',
    component: () => import('../views/AppTutorial2.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/appTutorial/3',
    name: 'App Tutorial 3',
    component: () => import('../views/AppTutorial3.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/appTutorial/4',
    name: 'App Tutorial 4',
    component: () => import('../views/AppTutorial4.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/appTutorial/5',
    name: 'App Tutorial 5',
    component: () => import('../views/AppTutorial5.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/appTutorial/6',
    name: 'App Tutorial 6',
    component: () => import('../views/AppTutorial6.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/modi',
    name: 'Modi',
    component: () => import('../views/Modi.vue'),
    meta: {
      requiresAuth: true,
      title: 'App Modi wählen - HfG Bikeshare'
    }
  },
  {
    path: '/bikeInfo',
    name: 'BikeInfo',
    component: () => import('../views/BikeInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Info - HfG Bikeshare'
    }
  },
  {
    path: '/bikeReserveInfo',
    name: 'BikeReserveInfo',
    component: () => import('../views/BikeReserveInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike reservieren - HfG Bikeshare'
    }
  },
  {
    path: '/bikeReserve',
    name: 'BikeReserve',
    component: () => import('../views/BikeReserve.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike reservieren - HfG Bikeshare'
    }
  },
  {
    path: '/bikeUnlock',
    name: 'BikeUnlock',
    component: () => import('../views/BikeUnlock.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike entsperren - HfG Bikeshare'
    }
  },
  {
    path: '/bikeUnlockRating',
    name: 'BikeUnlockRating',
    component: () => import('../views/BikeUnlockRating.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike bewerten - HfG Bikeshare'
    }
  },
  {
    path: '/bikeTutorial/1',
    name: 'BikeTutorial1',
    component: () => import('../views/BikeTutorial1.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/bikeTutorial/2',
    name: 'BikeTutorial2',
    component: () => import('../views/BikeTutorial2.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/bikeTutorial/3',
    name: 'BikeTutorial3',
    component: () => import('../views/BikeTutorial3.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/bikeTutorial/4',
    name: 'BikeTutorial4',
    component: () => import('../views/BikeTutorial4.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/bikeTutorial/5',
    name: 'BikeTutorial5',
    component: () => import('../views/BikeTutorial5.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/bikeTutorial/6',
    name: 'BikeTutorial6',
    component: () => import('../views/BikeTutorial6.vue'),
    meta: {
      requiresAuth: true,
      title: 'Bike Tutorial - HfG Bikeshare'
    }
  },
  {
    path: '/storage',
    name: 'Storage',
    component: () => import('../misc/ClearLocalStorage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Webspeicher - HfG Bikeshare'
    }
  },
  {
    path: '/pause',
    name: 'Pause',
    component: () => import('../views/Pause.vue'),
    meta: {
      requiresAuth: true,
      title: 'Fahrt pausieren - HfG Bikeshare'
    }
  },
  {
    path: '/pause/info',
    name: 'PauseInfo',
    component: () => import('../views/PauseInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Fahrt pausiert - HfG Bikeshare'
    }
  },
  {
    path: '/camera',
    name: 'Camera',
    component: () => import('../views/Camera.vue'),
    meta: {
      requiresAuth: true,
      title: 'Kamera - HfG Bikeshare'
    }
  },
  {
    path: '/camera/capture',
    name: 'CameraCapture',
    component: () => import('../views/CameraCapture.vue'),
    meta: {
      requiresAuth: true,
      title: 'Kamera - HfG Bikeshare'
    }
  },
  {
    path: '/menu/help',
    name: 'MenuHelp',
    component: () => import('../views/MenuHelp.vue'),
    meta: {
      requiresAuth: true,
      title: 'Hilfe - HfG Bikeshare'
    }
  },
  {
    path: '/menu/settings',
    name: 'MenuSettings',
    component: () => import('../views/MenuSettings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Einstellungen - HfG Bikeshare'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '404 - HfG Bikeshare'
    }
  },
]

// configures router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
