<template>
  <div style="background-color: var(--notclickable-color); height: 100vh; width: 100vw;">
    <b-container class="min-vh-100 d-flex flex-column" style="background-color: var(--notclickable-color);">
      <BackButton v-b-toggle.sidebar-menu/>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <!-- shows the logged in user name -->
          <h2 style="margin-bottom: 2rem; color: var(--white-color);">Hallo {{this.$store.state.userProfile.preName}}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col cols="10">
          <!-- FIX VERTICAL ICON / TEXT ALIGN -->
          <b-row class="whitecontainer flex-grow-1">
            <b-col class="justify-content-center align-self-center">
              <b-icon class="whitecontainerItems" scale="0.7" icon="cursor-fill" color="var(--visual-information-color)" style="margin-top: 1.2rem;" />
              <p style="color:var(--visual-information-color);">3,0 Kilometer</p>
            </b-col>
            <b-col>
              <img src="../assets/icons/ebike.svg" class="whitecontainerItems" style="margin-top: 1.5rem;"/>
              <p style="color:var(--visual-information-color);">2 Fahrten</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" />
      </b-row>
      <b-row>
        <b-col style="margin-top: 1.5rem; margin-bottom: 1.5rem;">
          <b-row class="menuItem">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;" @click="$router.push('/profil')">
                <b-icon class="icons" scale="1.8" icon="person-fill" />
                Profil
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row class="menuItem">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;" @click="$router.push('/time')">
                <b-icon class="icons" scale="1.5" icon="clock" />
                Zeit
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row class="menuItem">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;" @click="$router.push('/rating')">
                <b-icon class="icons" scale="1.5" icon="chat-text-fill" />
                Bewertung
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row class="menuItem">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;"
                @click="$router.push('/menu/settings')">
                <b-icon class="icons" scale="1.5" icon="gear-fill" />
                Einstellungen
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row class="menuItem">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;"
                @click="$router.push('/menu/help')">
                <b-icon class="icons" scale="1.5" icon="question-circle" />
                Hilfe
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row>
            <b-col cols="1" />
            <b-col cols="10">
              <hr style="background-color: var(--white-color)">
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row class="menuItem" style="margin-top: 0;">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;"
                @click="$router.push('/bugreport')">
                <b-icon class="icons" scale="1.5" icon="bug-fill" />
                Bug melden
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import BackButton from '../components/BackButton.vue'

  export default {
    name: 'Menu',
    components: {
      BackButton,
    }
  }
</script>

<style scoped>
  .menuItem {
    color: var(--white-color);
    margin-top: 1rem;
    cursor: pointer;
  }

  .icons {
    margin-right: 2rem;
  }

  .whitecontainer {
    background-color: var(--white-color);
    border-radius: 0.5rem;
    text-align: center;
  }

  .whitecontainerItems {
    width: 2rem;
    height: 2rem;
  }
</style>