<template>
  <div class="home-map">
    <transition name="fade">
      <!-- shows the loading screen until the map finished loading -->
      <LoadingScreen v-if="!showMap"/>
    </transition>
    <!-- adds the mapbox component -->
    <MglMap :accessToken="accessToken" :mapStyle="mapStyle" @load="onMapLoad" :zoom.sync="$store.state.mapState.zoom" :pitch.sync="$store.state.mapState.pitch" :center.sync="$store.state.mapState.center" :bearing.sync="$store.state.mapState.bearing" class="home-map">
    <!-- use our custom bike markers on the map -->
    <MglMarker v-for="bike in bikes" :key="bike._id" :coordinates="bike.coordinates" @click="selectBike(bike)">
      <BikeMarker slot="marker" :type="bike.type" :seen="bike.lastSeen"/>
    </MglMarker>
    
    <MglGeolocateControl v-if="showControls" />
    <MglNavigationControl v-if="showControls" />
  </MglMap>
  <MenuButton v-if="showControls"/>
  <TreeButton @click="showTreePopup=!showTreePopup" v-if="showControls"/>
  <TreePopup @click="showTreePopup=!showTreePopup" v-if="showTreePopup"/>
  </div>
</template>

<script>
  import Mapbox from "mapbox-gl";
  import BikeMarker from '../components/BikeMarker.vue';
  import LoadingScreen from '../components/LoadingScreen.vue';
  import TreeButton from '../components/TreeButton';
  import MenuButton from '../components/MenuButton';
  import TreePopup from '../components/TreePopup';

  import {
    MglMap,
    MglGeolocateControl,
    MglNavigationControl,
    MglMarker,
  } from "vue-mapbox";
  export default {
    name: 'MapComponent',
    components: {
      LoadingScreen,
      BikeMarker,
      TreeButton,
      MenuButton,
      MglMap,
      MglGeolocateControl,
      MglNavigationControl,
      MglMarker,
      TreePopup
    },
    data() {
      return {
        accessToken: "pk.eyJ1IjoidGFyYW1vbmhlaW0iLCJhIjoiY2tndGI1bGN2MGpmMTJ5cGI1M3R6ZjliaiJ9.qlDdaIFLkdW9gDptIBojaA",
        mapStyle: 'mapbox://styles/taramonheim/ckilxv1gn2ck717uj0vqz4h9j',
        showMap: false,
        showControls: false,
        showTreePopup: false,
        bikes: [],
        zoom: undefined,
      }
    },
    methods: {
      async onMapLoad(event) {
        const asyncActions = event.component.actions
        if (this.$store.state.mapLoaded) {
          // shows controls if map loaded
          this.showControls = true;
          setTimeout(() => this.showMap = true, 250);
        } else {
          this.showMap = true;
          // zooms to overview of gmuend on first app load
          await asyncActions.flyTo({
            center: [9.790, 48.798],
            zoom: 14,
            speed: 0.5
          })
          // shows the map controls after a short delay
          .then(() => setTimeout(() => this.showControls = true, 500));
          this.$store.state.mapLoaded = true;
        }
      },
      selectBike(bike) {
        // saves the choosen bike to the store and navigates to the detail screen
        this.$store.state.bike = bike;
        this.$router.push('/bikeInfo');
      },
      getBikes() {
        // gets all bikes from the database
        this.axios.get('/map/listBikes', {
          headers: {
            token: localStorage.getItem('token')
          }
        })
        .then((res) => {
          this.bikes = res.data.bikes;
        })
        .catch((err) => {
          this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden der Bikes', 'danger')
          console.error(err);
        });
      }
    },
    created() {
      // We need to set mapbox-gl library here in order to use it in template
      this.mapbox = Mapbox;
    },
    mounted() {
      this.getBikes();
      // updates all bikes on the map every 10 seconds
      setInterval(() => {
        this.getBikes();
      }, 10000);
    }
  }
</script>

<style scoped>
  .home-map {
    width: 100vw;
    height: 100vh;
  }
  .fade-leave-active {
  transition: opacity .5s;
}
  .fade-leave-to {
    opacity: 0;
  }
</style>