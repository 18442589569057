<template>
  <div class="home">
    <Map/>
  </div>
</template>

<script>
  import Map from '../components/Map';
  export default {
    name: 'Home',
    components: {
      Map,
    }
  }
</script>

<style scoped>

</style>