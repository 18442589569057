<template>
  <div>
    <!-- renders our app screens -->
    <router-view class="min-vh-100"/>
    <!-- slide in menu -->
    <b-sidebar id="sidebar-menu" shadow no-header width="100vw">
      <template>
        <Menu/>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import Menu from './components/Menu'
export default {
  name: 'App',
  components: {
    Menu
  },
  data() {
    return {
      registration: null,
      refreshing: false,
    }
  },
  // calls the get user profile method on app load
  created() {
    // this.$store.state.userProfile
    this.getUserProfile();
    document.addEventListener('appUpdated', (e) => {
      this.toast('Update', 'Es ist eine neue Version verfügbar', 'info');
      this.$store.state.registration = e.detail;
    });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      this.$router.push('/').then(() => window.location.reload());
    });
  },
  // sets page title for routes without a declared title
  watch: {
      '$route' (to) {
        document.title = to.meta.title || 'HfG Bikeshare'
      }
    },

}
</script>

<style>
/* adds our custom font */
@font-face {
  font-family: 'ArchiaLight';
  src: url(./fonts/Archia/archia-light-webfont.ttf) format("truetype");
}
@font-face {
  font-family: 'ArchiaRegular';
  src: url(./fonts/Archia/archia-regular-webfont.ttf) format("truetype");
}
@font-face {
  font-family: 'ArchiaMedium';
  src: url(./fonts/Archia/archia-medium-webfont.ttf) format("truetype");
}
@font-face {
  font-family: 'ArchiaSemiBold';
  src: url(./fonts/Archia/archia-semibold-webfont.ttf) format("truetype");
}
@font-face {
  font-family: 'ArchiaBold';
  src: url(./fonts/Archia/archia-bold-webfont.ttf) format("truetype");
}

/* declare global colors */
:root {
  --interaction-color: #ff9950;
  --danger-color: #ee684f;
  --warning-color: #ffc700;
  --text-icon-color: #525252;
  --notclickable-color: #a2c5b0;
  --background-color: #fbf6ee;
  /*--background-color: #dbdbdb;  remove later */
  --secondary-text-icon-color: #a8a8a8;
  --visual-information-color: #346957;
  --disabled-color: #f4f4f4;
  --white-color: #ffffff;
}

/* removed outline appearing after clicking on svgs */
*:focus {
  outline: 0;
}

/* declares our global font / text styles */
h1 {
  font-family: ArchiaSemiBold, Helvetica, Arial, sans-serif;
  /*font-weight: semibold;*/
  font-size: 2.25rem;
  color: var(--text-icon-color);
  margin-top: 4.25rem;
}
h2 {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  /*font-weight: medium;*/
  font-size: 1.625rem;
  color: var(--text-icon-color);
  margin-top: 4.25rem;
  word-wrap: break-word;
}
h3 {
  font-family: ArchiaSemiBold, Helvetica, Arial, sans-serif;
  /*font-weight: semibold;*/
  font-size: 1.25rem;
  color: var(--text-icon-color);
}
h4 {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  /*font-weight: medium;*/
  font-size: 1.063rem;
  color: var(--text-icon-color);
}
body {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  /*font-weight: medium;*/
  font-size: 1rem;
  color: var(--text-icon-color);
}
p {
  font-family: ArchiaRegular, Helvetica, Arial, sans-serif;
  /*font-weight: regular;*/
  font-size: 0.875rem;
  color: var(--text-icon-color);
  hyphens: auto;
}

/* overrides bootstrap styling to match our apps design */
.button {
  font-family: ArchiaSemiBold, Helvetica, Arial, sans-serif;
  /*font-weight: semibold;*/
  font-size: 1.125rem;
  background-color: var(--interaction-color);
  color: var(--white-color);
}
.btn-primary {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  color: var(--white-color);
  background-color: var(--interaction-color);
  border-color: var(--interaction-color);
  border-radius: 1.525rem;
}
.btn-primary:hover {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  color: var(--interaction-color);
  background-color: transparent;
  border-color: var(--interaction-color);
  border-radius: 1.525rem;
}
.btn-secondary {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  color: var(--interaction-color);
  background-color: transparent;
  border-color: var(--interaction-color);
  border-radius: 1.525rem;
}
.btn-secondary:hover {
  font-family: ArchiaMedium, Helvetica, Arial, sans-serif;
  color: var(--white-color);
  background-color: var(--interaction-color);
  border-color: var(--interaction-color);
  border-radius: 1.525rem;
}
.buttonSpacing {
  margin: 0.3rem;
}
.txtButton {
  text-align: center;
  color: var(--interaction-color);
  margin-top: 0.7rem;
  width: 100%;
  cursor: pointer;
}
.secondary-text {
  font-family: ArchiaRegular, Helvetica, Arial, sans-serif;
  /*font-weight: regular;*/
  font-size: 0.75rem;
  color: var(--secondary-text-icon-color);
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: var(--white-color);
    border-color: var(--interaction-color);
    background-color: var(--interaction-color);
}
.custom-control-label::before {
    background-color: var(--secondary-text-icon-color);
    border: var(--secondary-text-icon-color) solid 0.0625rem;
}
.custom-switch .custom-control-label::after {
    background-color: var(--white-color);
}
.progress {
  background-color: var(--secondary-text-icon-color);
  border-radius: 0.4rem;
  height: 0.5rem;
}
.progress-bar {
  background-color: var(--visual-information-color);
  border-radius: 0.4rem;
  height: 0.5rem;
}

/* always shows notifications on top of all elements */
.b-toast-danger .toast .toast-header {
  z-index: 100;
}

/* setting page indicator location */
.pageIndicatorPos {
  padding-top: 0.5rem;
  padding-bottom: 6.5rem;
  z-index: 5;
  background-color: var(--background-color);
  -webkit-box-shadow: 0rem 0rem 1.25rem 0.3125rem var(--background-color); 
  box-shadow: 0rem 0rem 0.625rem 0.625rem var(--background-color);
}

/* adding radius to camera view */
.webCamImg {
  border-radius: 0.5rem;
}
/* styling of the bottom box at pages with long text and buttons/page indicator - text disappearing behind it */
.box {
  height: 5rem;
  width: 100%;
  z-index: 5;
  position: fixed;
  bottom: 0rem;
  background-color: var(--background-color);
  -webkit-box-shadow: 0rem 0rem 1.25rem 0.3125rem var(--background-color); 
  box-shadow: 0rem 0rem 0.625rem 2rem var(--background-color);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* sets our background color on all screens by default */
body {
  background-color: var(--background-color);
}
</style>
