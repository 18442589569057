<template>
  <div class="tree-wrapper" @click="emitClick">
    <img src="@/assets/icons/tree5.svg" class="tree-icon" alt="tree"/>
    <!-- shows the amount of trees the user saved already -->
    <span class="tree-text">{{$store.state.userProfile.savedTrees}}</span>
  </div>
</template>

<script>
  export default {
    name: 'TreeButton',
    props: {
    },
    methods: {
      emitClick() {
        this.$emit('click');
      }
    }
  }
  
</script>

<style scoped>

.tree-wrapper {
  color: var(--visual-information-color);
  cursor: pointer;
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 1rem;
  right: 0rem;
  z-index: 50;
}

.tree-icon {
  color: var(--visual-information-color);
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0.3rem;
}

.tree-text {
  position: absolute;
  color: white;
  right: 1.53rem;
  bottom: 0.6rem;
}
</style>