<template>
  <div class="menu" v-b-toggle.sidebar-menu >
    <b-icon scale="2" icon="list"/>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  methods: {
    emitEvent(e) {
      this.$emit(e);
    }
  }
}
</script>

<style scoped>
.menu {
  color: var(--text-icon-color);
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 10;
  cursor: pointer;
}

</style>